import React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import MemorablePasswordGenerator from '../../tools/MemorablePasswordGenerator'

const MemorablePasswordGeneratorPage = () => (
    <Layout>
        <SEO title="Memorable Password Generator – Generate a Random & Strong Memorable Passphrase" />
        <section className="hero is-primary">
            <div className="hero-body">
                <div className="container is-fluid">
                    <h1 className="title">Memorable Password Generator</h1>
                    <h2 className="subtitle">
                        Generate a totally random memorable password by using
                        your ideal pattern of word, numbers, and separators.
                        Over are the days in which you forgot your own passwords
                        over and over again.
                    </h2>
                </div>
            </div>
        </section>

        <section className="section">
            <div className="container is-fluid">
                <MemorablePasswordGenerator />
            </div>
        </section>
    </Layout>
)

export default MemorablePasswordGeneratorPage
