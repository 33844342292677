import React, { useState } from 'react'
import { generateMemorablePassword } from '../lib/MemorablePwd'
import Textarea from '../components/Textarea'

const MemorablePasswordGenerator = () => {

	const [password, setPassword] = useState('')
    const [pattern, setPattern] = useState(`dswsw`)
    const [separators, setSeparators] = useState(`-.;=#+`)
    const [transformer, setTransformer] = useState(`alternate`)

    const generate = e => {
		e.preventDefault()

		let opts = {}

		if (pattern.length > 0)
			opts.pattern = pattern

		if (separators.length > 0)
			opts.separators = separators

		if (transformer.length > 0)
			opts.transform = transformer.toLowerCase()

		let pass = generateMemorablePassword(opts)
        setPassword(pass)
    }

    return (
		<>
			<div className="columns is-desktop">
			<form className="column" onSubmit={generate}>

				<div className="field">
					<label className="label" htmlFor="pattern">
						Pattern
					</label>
					<div className="control">
						<input
							className="input"
							type="text"
							id="pattern"
							placeholder="dswswsw"
							value={pattern}
							onChange={e => setPattern(e.target.value)}
						/>
					</div>
					<p className="help">Patterns can consist of any combination of the following: (w)ords, (d)igits, (s)eparators.</p>
				</div>

				<div className="field">
					<label className="label" htmlFor="separators">
						Separators
					</label>
					<div className="control">
						<input
							className="input"
							type="text"
							id="separators"
							placeholder="-.;=#+"
							value={separators}
							onChange={e => setSeparators(e.target.value)}
						/>
					</div>
				</div>

				<div className="field">
					<label className="label" htmlFor="transformer">
						Transformation
					</label>
					<div className="control">
						<div className="select is-primary">
							<select id="transformer" value={transformer} onChange={e => setTransformer(e.target.value)}>
								<option value="alternate">alternating WORD case</option>
								<option value="lowercase">lower case</option>
								<option value="uppercase">UPPER CASE</option>
								{/* <option value="NONE">-none-</option> */}
								{/* <option value="CAPITALISE">Capitalise First Letter</option> */}
								{/* <option value="INVERT">cAPITALISE eVERY lETTER eXCEPT tHe fIRST</option> */}
								{/* <option value="RANDOM">EVERY word randomly CAPITALISED or NOT</option> */}
							</select>
						</div>
					</div>
				</div>

				<div>
					<button
						type="submit"
						className="button is-primary"
					>
						Generate
					</button>
				</div>
			
			</form>

			<div className="column is-four-fifths">
				<Textarea
					className="textarea"
					style={{
						width: `100%`,
						backgroundColor: `#fff`,
						padding: `1rem`,
						fontFamily: `monospace`,
					}}
					rows={1}
					value={password}
					showCopyToClipboard={true}
					readOnly={true}
				/>
			</div>
		</div>
		</>
	)
}

MemorablePasswordGenerator.propTypes = {}

MemorablePasswordGenerator.defaultProps = {}

export default MemorablePasswordGenerator
